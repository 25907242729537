<template>
  <div v-if="productArray">
    <v-container fluid class="px-10">
      <v-row align="center">
        <v-img :src="require('@/assets/images/product/20200617105031.png')" max-width="40" max-height="40"> </v-img>
        <h2>{{ category }}</h2>
      </v-row>
      <v-row justify="start" class="hidden-sm-and-down">
        <v-col v-for="(item, index) in productArray" :key="index" cols="6" md="4">
          <v-card class="mx-auto" max-width="270">
            <router-link :to="`/${$route.name}/${item.id}`">
              <v-img :src="item.product_photo" height="200px"> </v-img>
            </router-link>

            <v-card-title>
              <h3>{{ item.product_name }}</h3>
            </v-card-title>

            <v-card-subtitle class="red--text pb-0 mt-1">
              <h4>
                價格：{{ item.amountShow == 0 ? item.price : item.price * item.amountShow }}
                <br />
                PV值：
                {{ item.amountShow == 0 ? item.pv : item.pv * item.amountShow }}
              </h4>
            </v-card-subtitle>

            <v-card-actions>
              <v-col cols="8" class="px-1">
                <v-chip label color="white" class="px-0">
                  <v-icon color="green" @click="item.amountShow == 0 ? null : item.amountShow--"> mdi-minus </v-icon>
                  {{ item.amountShow }}
                  <v-icon color="red" @click="item.amountShow++"> mdi-plus </v-icon>
                </v-chip>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn icon color="white" width="33" style="background: #d30060" @click="addToCart(item)">
                <v-icon>mdi-cart-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="start" class="hidden-md-and-up">
        <v-col v-for="(item, index) in productArray" :key="index" cols="12" sm="12">
          <v-card class="mx-auto" max-width="500">
            <v-row>
              <v-col>
                <router-link :to="`/${$route.name}/${item.id}`">
                  <v-img :src="item.product_photo" height="200px"> </v-img>
                </router-link>
              </v-col>
              <v-col>
                <v-card-title>
                  <h3>{{ item.product_name }}</h3>
                </v-card-title>

                <v-card-subtitle class="red--text pb-0 mt-1">
                  <h4>
                    價格：{{ item.amountShow == 0 ? item.price : item.price * item.amountShow }}
                    <br />
                    PV值：
                    {{ item.amountShow == 0 ? item.pv : item.pv * item.amountShow }}
                  </h4>
                </v-card-subtitle>
                <v-card-actions>
                  <v-col cols="8" class="px-1">
                    <v-chip label color="white" class="px-0">
                      <v-icon color="green" @click="item.amountShow == 0 ? null : item.amountShow--">
                        mdi-minus
                      </v-icon>
                      {{ item.amountShow }}
                      <v-icon color="red" @click="item.amountShow++"> mdi-plus </v-icon>
                    </v-chip>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-btn icon color="white" width="33" style="background: #d30060" @click="addToCart(item)">
                    <v-icon>mdi-cart-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: String,
      required: true,
    },
    productArray: {
      type: Array,
      required: true,
    },
  },
  created() {
    // 把購物車的小計及顯示在產品頁面的小計塞入到陣列物件中
    // https://vuejs.org/v2/guide/reactivity.html
    this.productArray = this.productArray.map((element) => {
      return { ...element, amount: 0, amountShow: 0 };
    });
    console.log(this.productArray);
  },
  methods: {
    addToCart(parm) {
      if (parm.amountShow != 0) {
        if (this.$store.state.userCart.length != 0 && this.$route.name == 'Resell') {
          for (let i = 0; i < this.$store.state.userCart.length; i++) {
            if (this.$store.state.userCart[i].store_name === 'VIP商城') {
              return this.$Message.error('重銷商城與VIP商城之商品無法一同結帳,請確認購物車商品');
            }
          }
        }
        if (this.$store.state.userCart.length != 0 && this.$route.name == 'Vip_Store') {
          for (let i = 0; i < this.$store.state.userCart.length; i++) {
            if (this.$store.state.userCart[i].store_name === '重銷商城') {
              return this.$Message.error('重銷商城與VIP商城之商品無法一同結帳,請確認購物車商品');
            }
          }
        }
        parm.amount += parm.amountShow;
        this.$store.dispatch('doAddItemToCart', parm);
        this.$Message.success('成功加入購物車');
        parm.amountShow = 0;
      }
    },
  },
};
</script>
